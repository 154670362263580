<template>
    <div>
        <v-row>
            <v-col>
                <!--<main-img></main-img>-->
                <div class="" style="width: 100%;">
                    <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                이용후기
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                이용후기
                            </h1>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-container>
                    <v-row>
                        <v-col cols="12" v-resize="onResize">
                            <ul id="subMenu-ul" style="width:100%; margin:0 auto !important;"
                                class="roundBtn-blue calc-6">
                                <li>
                                    <a id="1" @click="goToPage('1')">공지사항</a>
                                </li>
                                <li>
                                    <a id="3" class="active" @click="goToPage('3')">이용후기</a>
                                </li>
                                <li>
                                    <a id="4" @click="goToPage('4')">문의하기</a>
                                </li>
                                <li>
                                    <a id="2" @click="goToPage('2')">포토갤러리</a>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                    color="primary"
                                    class="mx-4  center-block float-right my-2"
                                    @click="btnInsertClick"
                            >
                                등록
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-row>
                                <v-col
                                        v-for="(item,n) in items"
                                        :key="n"
                                        class="d-flex child-flex"
                                        cols="6" sm="3">
                                    <v-card
                                            class="borderPhoto"
                                            @click="rowClick(item)">
                                        <template v-if="item.boardThumb.length > 0">
                                            <v-img
                                                    :src="item.boardThumb"
                                                    :lazy-src="item.boardThumb"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                            ></v-img>
                                        </template>
                                        <template v-else>
                                            <v-img
                                                    src="../../assets/NoImg.png"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                            ></v-img>
                                        </template>
                                        <!--{{item.boardThumb}}-->
                                        <v-card-title class="text-h6">
                                            {{item.boardTitle}}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{item.lstModPrs}}
                                        </v-card-subtitle>
                                    </v-card>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-divider
                            ></v-divider>

                            <div class="text-center pt-2">
                                <v-pagination
                                        v-model="page.page"
                                        :length="page.length"
                                        @input="next"
                                ></v-pagination>
                            </div>
                        </v-col>

                    </v-row>
                    <v-row style="margin-top:0">
                        <v-col cols="3" class="hidden-sm-and-down"></v-col>
                        <v-col cols="12" md="6" class="table-left-s" style="border:0px;">
                            <div class="search-form">
                                <select v-model="search.searchGubun" class="float-left my-1 select-s">
                                    <!--<option v-for="(n, i) in 5" :value="n">{{n}}</option>-->
                                    <option value="0">제목</option>
                                    <option value="1">내용</option>
                                </select>
                                <input v-model="search.searchText" placeholder="검색어를 입력해주세요."
                                       v-on:keyup.enter="fetch({})">
                                <v-btn
                                        color="primary"
                                        class="mx-1 my-1 white--text center-block tmp-btn"
                                        small
                                        @click="fetch({})"
                                >
                                    검색
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="3" class="hidden-sm-and-down"></v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>

    export default {
        name: 'Review',
        components: {},
        data: () => ({
            isMobile: true,
            isHeader: true,

            headers: [],
            items: [],
            search: {
                searchGubun: "0",
                searchText: "",
            },
            page: {
                limit: 8,  // 한페이지 표시 갯수
                offset: 0,  // %부터 보여주기
                total: '0', // 전체 갯수
                page: 1,    // 현제 페이지
                length: 1   // 페이지 표시 갯수
            },
            boardGubun: "3",

        }),
        created: function () {
            window.scrollTo(0, 0);
            // 1: 공지사항 , 2: 포토 , 3: 후기, 4: 고객센터
            this.fetch();

        },
        methods: {
            onResize() {

                $('#subMenu-ul').removeClass('calc-4');
                $('#subMenu-ul').removeClass('calc-6');

                if (window.innerWidth < 700) {
                    $('#subMenu-ul').addClass('calc-4');

                } else {
                    $('#subMenu-ul').addClass('calc-6');
                }
            },
            fetch(val) {

                let formdata = {};
                if (val === undefined) {
                    formdata = {
                        boardGubun: this.boardGubun
                    };
                } else {
                    if (this.search.searchGubun == "0") {
                        formdata = {
                            boardGubun: this.boardGubun,
                            titleLike: this.search.searchText,
                        };
                    } else {
                        formdata = {
                            boardGubun: this.boardGubun,
                            textLike: this.search.searchText,
                        };
                    }
                }

                formdata['limit'] = this.page.limit;
                formdata['offset'] = this.page.offset;

                return this.$store.dispatch("board/getBoardList", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.items = resp.message;
                            this.page.total = resp.total;
                            this.setPage();

                            for (let i = 0; this.items.length > i; i++) {

                                this.items[i].boardTitle = this.items[i].boardTitle + "  (" + this.items[i].comtCnt + ")"

                            }

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            btnInsertClick() {
                location.href = "/community/write" + '?boardGubun=' + this.boardGubun;
            },
            rowClick(row) {
                let fdata = {
                    boardId: row.boardId
                };

                return this.$store.dispatch("board/updateHitCnt", fdata)
                    .then((resp) => {
                        setTimeout(() => {
                            location.href = "/community/read" + '?boardId=' + row.boardId + '&boardGubun=' + this.boardGubun;
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            goToPage(val) {

                switch (val) {
                    case "1": {
                        location.href = "/community/notice";
                        break;
                    }
                    case "2": {
                        location.href = "/community/photo";
                        break;
                    }
                    case "3": {
                        location.href = "/community/review";
                        break;
                    }
                    case "4": {
                        location.href = "/community/customer";
                        break;
                    }
                }
            },
            setPage() {

                this.page.length = Math.ceil(this.page.total / this.page.limit);
                if (this.page.length === 0) this.page.length = 1;

            },
            next(page) {
                this.page.page = page;
                this.page.offset = this.page.limit * (this.page.page - 1);
                //this.getList();
                this.fetch({});
            },

        }
    }
</script>

<style scoped>

</style>

